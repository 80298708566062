(function Os() {
  let ua = window && window.navigator.userAgent,
  isWindowsPhone = /(?:Windows Phone)/.test(ua),
  isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
  isAndroid = /(?:Android)/.test(ua),
  isFireFox = /(?:Firefox)/.test(ua),
  isTablet = /(?:iPad|PlayBook)/.test(ua) || isAndroid && !/(?:Mobile)/.test(ua) || isFireFox && /(?:Tablet)/.test(ua),
  isiPhone = /(?:iPhone)/.test(ua) && !isTablet,
  isPc = !isiPhone && !isAndroid && !isSymbian;
  window.isMobile = isAndroid || isiPhone
  window.isPc = isPc
})();